import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import EmbedPlayer from '../../components/EmbedPlayer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3>{`Multiview example`}</h3>
    <p>{`In this example the content of the player is loaded and changed dynamically, providing an easy way for seamless switching between live or VOD contents.`}</p>
    <p>{`This feature of the Embed API can be used in various use cases, like switching between`}</p>
    <ul>
      <li parentName="ul">{`multiple cameras`}</li>
      <li parentName="ul">{`simultaneous sessions of a conference`}</li>
      <li parentName="ul">{`multi-language streams`}</li>
      <li parentName="ul">{`or to promote further channels.`}</li>
    </ul>
    <h3>{`Example`}</h3>
    <EmbedPlayer src="https://video.ibm.com/embed/23935269" controls={['multi']} mdxType="EmbedPlayer" />
    <h3>{`HTML`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="player-container">
    <iframe
      id="MultiviewPlayer"
      src="//video.ibm.com/embed/23935269"
      frameborder="0"
      allowfullscreen
      webkitallowfullscreen
      scrolling="no"
      referrerpolicy="no-referrer-when-downgrade"
    >
    </iframe>
</div>

<div class="multiview-chooser">
  <ul>
    <li>
      <a href="#" class="active" data-app="channel" data-id="23935269">
        <img
<!--      users can override it with custom images -->
          src="https://ustvstaticcdn1-a.akamaihd.net/i/channel/live/1_23935269,640x360,b:2020091508.jpg"
          alt="Demo channel"
        />
      </a>
    </li>
    <li>
      <a href="#" data-app="channel" data-id="17074538">
        <img
          src="https://ustvstaticcdn1-a.akamaihd.net/i/channel/live/1_17074538,640x360,b:2020091508.jpg"
          alt="ISS HD Earth Viewing Experiment"
        />
      </a>
    </li>
    <li>
      <a href="#" data-app="channel" data-id="10414700">
        <img
          src="https://ustvstaticcdn1-a.akamaihd.net/i/channel/live/1_10414700,640x360,b:2020090903.jpg"
          alt="Demo channel"
        />
      </a>
    </li>
  </ul>
</div>
`}</code></pre>
    <h3>{`JavaScript`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var _contents = document.querySelectorAll('.multiview-chooser a'),
    _active = _contents[0],
    embedApi = UstreamEmbed('MultiviewPlayer');

Array.prototype.forEach.call(_contents, function (_a){
    _a.onclick = function(e){
        e.preventDefault();
        e.stopPropagation();

        embedApi.callMethod(
            'load',
            this.getAttribute('data-app'),
            this.getAttribute('data-id')
        );

        _active.classList.remove('active');
        this.classList.add('active');
        _active = this;

        return false;
    };
});
`}</code></pre>
    <h3>{`CSS`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.player-container {
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
}

.player-container iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.multiview-chooser {
    margin-top: 1rem;
}

.multiview-chooser ul {
    margin: 0;
}

.multiview-chooser li {
    width: 24%;
    float: left;
    margin: 0;
    padding: 0;
}

.multiview-chooser li:not(:first-of-type) {
    margin-left: 1.33%;
}

.multiview-chooser li:before {
    display: none;
}

.multiview-chooser a {
    display: block;
}

.multiview-chooser a.active:after {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    box-sizing: border-box;
    border: 3px solid #38f;
}

.multiview-chooser img {
    margin: 0;
    padding: 0;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      